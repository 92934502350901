<template>
    <div class="error-page">
        <div class="error-page-svg">
            <el-image style="width: 500px; height: 500px" :src="img"></el-image>
        </div>
        <router-link to="/mall/pages-manage">
            <el-button type="primary">回到首页</el-button>
        </router-link>
    </div>
</template>
<script>
import img from '@/assets/image/404.jpg'
export default {
    name: 'Error-404',
    data() {
        return {
            img
        }
    }
}
</script>
<style lang="scss" scoped>
.error-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-svg {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }
}
</style>